import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  setup(__props) {
    var _hmt = _hmt || [];

    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?84c3742dbbc9c89d315b2311b23310b3";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};