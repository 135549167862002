import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import {TOKEN,LOGIN_ROUTER} from '@/const/index.js';
import {getItem} from '@/utils/utils'

//PC端路由
const routerPC = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index.vue")
  },
  {
      path: "/login",
      name: "Login",
      component: () => import("@/views/home/login.vue")
  },
  {
    path: "/toutiao/hd/add",
    name: "ToutiaoAdd",
    meta: {
        title: '头条投放助手_Topsocial数播｜今日头条互动数获取｜头条互动数｜头条互动数批量获取｜投放结算工具',
        keywords: '头条投放结算工具｜topsocial｜数播｜头条互动数获取｜头条阅读数批量获取｜头条文章数据批量获取｜头条文章传播情况｜文章互动情况｜头条文章互动数批量获取｜今日头条文章数据｜文章互动数批量下载', // 设置页面关键词
        description: '头条互动数批量获取工具，今日头条投放批量获取投放数据结果，多用于企业投放结算，短时间内获取全部文章互动数，大大提高团队人效。',
    },
    component: () => import("@/views/toutiao/interactions/add.vue")
  },
  {
    path: "/toutiao/hd/list",
    name: "ToutiaoList",
    component: () => import("@/views/toutiao/interactions/list.vue")
  },
  {
      path: "/toutiao/hd/detail",
      name: "ToutiaoDetail",
      component: () => import("@/views/toutiao/interactions/detail.vue")
  },
  {
      path: "/toutiao/hd/weibo_detail",
      name: "WeiboDetail",
      component: () => import("@/views/toutiao/interactions/weibo_detail.vue")
  },
  {
      path: "/toutiao/hd/douyin_detail",
      name: "DouyinDetail",
      component: () => import("@/views/toutiao/interactions/douyin_detail.vue")
  },
  {
      path: "/toutiao/hd/redbook_detail",
      name: "RedbookDetail",
      component: () => import("@/views/toutiao/interactions/redbook_detail.vue")
  },
  {
      path: "/toutiao/hd/member",
      name: "ToutiaoMember",
      component: () => import("@/views/toutiao/interactions/member.vue")
  },
  {
      path: "/toutiao/hd/equity_list",
      name: "EquityList",
      component: () => import("@/views/toutiao/interactions/equity_list.vue")
  },
    {
        path: "/toutiao/hd/expire_list",
        name: "ExpireList",
        component: () => import("@/views/toutiao/interactions/expire_list.vue")
    },
  {
    path: "/search/hot/index",
    name: "SearchHotIndex",
    meta: {
      title: '历史热搜查询',
      keywords:'历史热搜查询｜topsocial｜数播｜social｜微博热搜分钟榜｜热搜榜单最高位｜热搜在位时长｜历史热搜上榜查询｜热搜搜索量峰值｜抖音热榜｜历史热榜｜头条榜单｜知乎热搜榜单｜历史热搜查询工具',
    },
    component: () => import("@/views/search/hot/index.vue")
  },
  {
    path: "/search/hot/focus",
    name: "SearchHotFocus",
    meta: {
      title: '历史热搜查询',
      keywords:'历史热搜查询｜topsocial｜数播｜social｜微博热搜分钟榜｜热搜榜单最高位｜热搜在位时长｜历史热搜上榜查询｜热搜搜索量峰值｜抖音热榜｜历史热榜｜头条榜单｜知乎热搜榜单｜历史热搜查询工具',
    },
    component: () => import("@/views/search/hot/focus.vue")
  },
  {
    path: "/search/hot/cloud",
    name: "SearchHotCloud",
    meta: {
      title: '热搜词云',
      keywords:'热搜词云｜topsocial｜数播｜social｜微博热搜分钟榜｜热搜榜单最高位｜热搜在位时长｜历史热搜上榜查询｜热搜搜索量峰值｜抖音热榜｜历史热榜｜头条榜单｜知乎热搜榜单｜历史热搜查询工具',
    },
    component: () => import("@/views/search/hot/cloud.vue")
  },
	{
	    path: "/kol_account/add",
	    name: "kolAccountAdd",
      meta: {
          title: '获取账号数据_Topsocial数播',
      },
	    component: () => import("@/views/kol_account/add.vue")
	},
    {
        path: "/kol_account/list",
        name: "kolAccountList",
        meta: {
          title: '获取账号数据_Topsocial数播',
        },
        component: () => import("@/views/kol_account/list.vue")
    },
    {
        path: "/kol_account/toutiao_detail",
        name: "kolAccountToutiaoDetail",
        meta: {
          title: '获取账号数据_Topsocial数播',
        },
        component: () => import("@/views/kol_account/toutiao_detail.vue")
    },
    {
        path: "/kol_account/douyin_detail",
        name: "kolAccountDouyinDetail",
        meta: {
          title: '获取账号数据_Topsocial数播',
        },
        component: () => import("@/views/kol_account/douyin_detail.vue")
    },
    {
        path: "/kol_account/redbook_detail",
        name: "kolAccountRedbookDetail",
      meta: {
        title: '获取账号数据_Topsocial数播',
      },
        component: () => import("@/views/kol_account/redbook_detail.vue")
    },
    {
        path: "/kol_account/weibo_detail",
        name: "kolAccountWeiboDetail",
      meta: {
        title: '获取账号数据_Topsocial数播',
      },
        component: () => import("@/views/kol_account/weibo_detail.vue")
    },
    {
      path: "/screenshot/add",
      name: "ScreenshotAdd",
      meta: {
        title: '发布凭证存档_Topsocial数播',
      },
      component: () => import("@/views/screenshot/add.vue")
    },
    {
      path: "/screenshot/list",
      name: "ScreenshotList",
      meta: {
        title: '发布凭证存档_Topsocial数播',
      },
      component: () => import("@/views//screenshot/list.vue")
    },
];



const router = createRouter({
  // history: createWebHashHistory(), //hash为页面重置跳转首页
  history: createWebHistory(),        //history为页面不重置跳转首页
  routes:routerPC
})
router.beforeEach((to, from,nuxt)=>{
  document.title = to.meta.title ? to.meta.title : '作品数据批量获取';

  // 更新 keywords meta 标签
  const keywords = to.meta.keywords || '';
  const keywordsElement = document.querySelector('meta[name="keywords"]');
  if (keywordsElement) {
      keywordsElement.setAttribute('content', keywords);
  }

  // 更新 description meta 标签
  const description = to.meta.description || '';
  const descriptionElement = document.querySelector('meta[name="description"]');
  if (descriptionElement) {
      descriptionElement.setAttribute('content', description);
  }

  //需要登录的路由
  if(getItem(TOKEN)){
    nuxt()
  }else{
    if(LOGIN_ROUTER.includes(to.path)){
      nuxt(from.path)
    }else{
      nuxt()
    }
    //跳转首页
    // if(to.path=='/'){
    //   nuxt('/toutiao/hd/add')
    // }else{
    //
    // }
  }
});
// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g;
//   const isChunkLoadFailed = error.message.match(pattern);
//   const targetPath = router.history.pending.fullPath;
//   if (isChunkLoadFailed) {
//     router.replace(targetPath);
//   }
// });
export default router
