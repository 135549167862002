const firstRecord = {
    // 记录第一次进行url
    RECORD_URL:'RECORD_URL',
    // 记录统计来源数据
    RECORD_SOURCE:'RECORD_SOURCE',
    RECORD_KEYWORDS:'RECORD_KEYWORDS',
    RECORD_PLAN:'RECORD_PLAN',
    RECORD_OPEN_TIME:'RECORD_OPEN_TIME',
  }
// const USER_INFO='USER_INFO';
const USER={
  //问券调查弹窗
  SHOW_WELCOME:'SHOW_WELCOME',
  SHOW_WELCOMECLOSE:'SHOW_WELCOMECLOSE'
}
const USER_AUTH = {
  // 用户权限状态
  SUCCESS:1,//正常
  ERROR:0,//失败
  USER_NOT_LOGIN:-1, //未登录
  USER_UNAUTH:1001,//无权限
  USER_NOT_TIMES:1002,//次数用户
}
const TOKEN = 'Authorization'
const TOKEN2 = 'Authorization2'
//需要登录的路由
const LOGIN_ROUTER = []
export{
  //第一次进入url的数据
  firstRecord,
  USER,
  USER_AUTH,
  TOKEN,
  TOKEN2,
  LOGIN_ROUTER
}
